import React from "react";
import "./About.css";
import Navbar from "../../Layout/Navbar/Navbar";
import Footer from "../../Layout/Footer/Footer";
import AboutUsImage from "../../img/About-Us/About-us.jpg";

import transparency from "../../img/Transperency.png";
import fairness from "../../img/fairness.png";
import building from "../../img/building.png";
import assets from "../../img/assets.jpg";
import accessibility from "../../img/accessibility.png";
import BPTimage from '../../img/Logo/logo1.png';

export default function About() {
  return (
    <>
      <Navbar />
      <div className="container py-5 mt-3 mb-4">
        <div className="row py-5">
          <h1 className="text-center"><b>About Us</b></h1> <hr />
          <div className="col-md-6">
            <img
              src={AboutUsImage}
              className="img-fluid about-img"
              alt="About Us"
            />
          </div>
          <div className="col-md-6">
            <h3 className="text-center mt-4">
            <b>Welcome to</b> <img src={BPTimage} alt="BidProTrade" height={95} style={{marginLeft: "-15px"}} />
              <h3 >Middleman to the sustainable future</h3> 
            </h3>

            <p className="mt-3">
              We are proud to be the first-ever platform that encourages green
              energy usage with a dedicated reverse auction system for a range
              of biomass briquet products.
              <br />
              With us, buyers skip the whole hassle of finding and going through
              quotations from multiple vendors to find the best deal. Instead,
              we bring the other end to the buyer and make them compete to
              provide the lowest price possible.
              <br />
              The suppliers on our platform have to go through strict KYC
              checks, so the buyer doesn't have to limit themselves to the deals
              offered by only their trustworthy neighborhood supplier.
              <br />
              With features like real-time reverse bidding, auto-quote
              generation and comparison, secure payment system, partial volume
              allocation, and much more, you can focus on getting the best deal
              without stressing about connectivity and security.
            </p>
          </div>
          <div className="row mt-5">
            <div className="col-md-4">
              <ul class="list-group">
                <li class="list-group-item active" aria-current="true" style={{backgroundColor: "#1a7fb0"}}>
                  <b>Bid</b> with Vision
                </li>
                <li class="list-group-item">
                  We bridge the gap between buyer and seller by inhibiting a
                  healthy competitive environment through reverse auctioning,
                  where the buyer holds power
                </li>
              </ul>
            </div>

            <div className="col-md-4">
              <ul class="list-group">
                <li class="list-group-item active-fai" aria-current="true">
                  <b>Pro</b> factor Mission
                </li>
                <li class="list-group-item">
                  To be a user-friendly, safe space for our customers to achieve
                  the most efficient and affordable solution so as to promote
                  sustainable energy alternatives.
                </li>
              </ul>
            </div>

            <div className="col-md-4  mb-4">
              <ul class="list-group">
                <li class="list-group-item active-int" aria-current="true">
                  <b>Trade </b>with Values
                </li>
                <li class="list-group-item">
                  we believe in trading with our values such as.
                </li>
              </ul>
            </div>
          </div>
         
          <div class="container1 mx-auto mt-2 mt-100">
            <div className="row" style={{ justifyContent: "center" }}>
              <div className="card1 col-md-3 mt-100">
                <div className="card-content">
                  <div className="card-body p-0">
                    <div className="profile">
                      {" "}
                      <img
                        src={transparency}
                        style={{ border: "8px solid #aaae37" }}
                        alt="transparency"
                      />{" "}
                    </div>
                    <div className="card-title">Transparency</div>
                    <div className="card-subtitle">
                      <p>
                        {" "}
                        <small className="text-muted">
                          {" "}
                          Where you know the quality of the product you are to
                          bid for, the process where it is getting bid on, and
                          trace the procured product.
                        </small>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card1 col-md-3 mt-100">
                <div class="card-content">
                  <div class="card-body p-0">
                    <div class="profile">
                      {" "}
                      <img
                        src={fairness}
                        style={{ border: "8px solid #0a5142" }}
                        alt="fairness"
                      />
                    </div>
                    <div class="card-title">Fairness</div>
                    <div class="card-subtitle">
                      <p>
                        {" "}
                        <small class="text-muted">
                          {" "}
                          Where bidders are given a completely unbiased ground
                          to work on.
                        </small>{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card1 col-md-3 mt-100">
                <div class="card-content">
                  <div class="card-body p-0">
                    <div class="profile">
                      <img
                        src={building}
                        style={{ border: "8px solid #1a7fb0" }}
                        alt="builiding"
                      />
                    </div>
                    <div class="card-title">Community building</div>
                    <div class="card-subtitle">
                      <p>
                        <small class="text-muted">
                          Where you connect with diverse people that share your
                          passion in the field.
                        </small>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card1 col-md-3 mt-100">
                <div class="card-content">
                  <div class="card-body p-0">
                    <div class="profile">
                      <img
                        src={assets}
                        style={{ border: "8px solid #1e6a43" }}
                        alt="assets"
                      />
                    </div>
                    <div class="card-title">Intellectual assets</div>
                    <div class="card-subtitle">
                      <p>
                        <small class="text-muted">
                          Where you can depend on us to bring you the most
                          reliable information regarding your product of
                          interest.
                        </small>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card1 col-md-3 mt-100">
                <div class="card-content">
                  <div class="card-body p-0">
                    <div class="profile">
                      <img src={accessibility} alt="accessibility" />
                    </div>
                    <div class="card-title">Accessibility</div>
                    <div class="card-subtitle">
                      <p>
                        <small class="text-muted">
                          Where you don’t have to be intimidated by a
                          complicated system.
                        </small>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
