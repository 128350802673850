import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Blog.css";
import Embrace from "../../img/Embrace.jpg";
import future from "../../img/future.jpg";

export default function Blog() {
  const img =
    "https://www.networkforphl.org/wp-content/uploads/2020/01/iStock-1032683612-1392x0-c-default-q40.jpg";

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplay: true,
    autoplaySpeed: 4000,
    rtl: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="container py-5 mt-5">
      <h1 className="text-center mb-5" style={{ fontWeight: "bold" }}>
        Blogs & Articles
      </h1>
      <hr />
      <div className="row py-5">
        <Slider {...settings}>
          <div className="col-md-4">
            <div className="card">
              <img src={Embrace} className="img-fluid" alt="..." />
              <div className="card-body">
                <h5 className="card-title mt-4 mb-5">
                All you need to know about Solar Subsidy
                </h5>
                <a
                  href="https://www.citizensolar.com/subsidy-on-solar-panel/"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  <span className="badge rounded-pill bg-primary">
                    Read More
                  </span>
                </a>
              </div>
            </div>
          </div>

          <div className="col">
            <div className="card">
              <img src={future} className="img-fluid" alt="..." />
              <div className="card-body">
                <h5 className="card-title mt-4 mb-4">
                  Embrace green tech revolution or risk falling behind, new UN
                  report warns
                </h5>
                <a
                  href="https://news.un.org/en/story/2023/03/1134672"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  <span className="badge rounded-pill bg-primary">
                    Read More
                  </span>
                </a>
              </div>
            </div>
          </div>

          <div className="col">
            <div className="card">
              <img src={img} className="img-fluid" alt="..." />
              <div className="card-body">
                <h5 className="card-title mt-4 mb-5">
                  Future of solar energy expansion in India
                </h5>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://timesofindia.indiatimes.com/blogs/voices/future-of-solar-energy-expansion-in-india/"
                >
                  {" "}
                  <span className="badge rounded-pill bg-primary">
                    Read More
                  </span>
                </a>
              </div>
            </div>
          </div>
        </Slider>
      </div>
    </div>
  );
}
