import React, { useState } from "react";
import "./Profile.css";
import Navbar from "../../Layout/Navbar/Navbar";
import Footer from "../../Layout/Footer/Footer";
import WaareeSolor from "../../img/sellers/waaree-solar.jpg";
import MahindraSolar from "../../img/sellers/mahindta-solar.jpg";
import AdaniSolar from "../../img/sellers/adani-solar.jpg";
import AnanyaSolar from "../../img/Attraction/ananya-solar.png";
import AdityaSolar from "../../img/Attraction/aditya-energy.png";
import ECEIndia from "../../img/Attraction/ece-india.png";
import LoomSolar from "../../img/sellers/loom-solor.jpg";
import LuminousSolar from "../../img/sellers/luminous-solar.jpg";
import VikramSolar from "../../img/sellers/vikram-solar.jpg";
import TataPowerSolor from "../../img/sellers/tata-solar.jpg";

import PromoCode from "../../img/discount.png";

export default function Profile() {
  const [auctionTitle, setAuctionTitle] = useState("Best 5 Bids For You!");
  const [quotesTitle, setQuotesTitle] = useState("Best 5 Quotes For You!");
  const [currentStep, setCurrentStep] = useState(0);

  const handleConfirm = (event) => {
    event.preventDefault();
    setAuctionTitle("Submission");
    setCurrentStep(currentStep + 1);
  };

  const handleConfirm1 = (event) => {
    event.preventDefault();
    setQuotesTitle("Submission");
    setCurrentStep(currentStep + 1);
  };

  const onHandleClose = () => {
    setAuctionTitle("Best 5 Bids For You!");
    setQuotesTitle("Best 5 Quotes For You!");
    setCurrentStep(0);
  };

  return (
    <>
      <Navbar />
      <div className="container py-5 mt-5">
        <div className="row py-5">
          <h1>
            {" "}
            <b>My Profile</b>
          </h1>{" "}
          <hr />
          <div className="accordion accordion-flush" id="accordionFlushExample">
            <div className="accordion-item">
              <h2 className="accordion-header" id="flush-headingTwo">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseOne"
                  aria-expanded="false"
                  aria-controls="flush-collapseOne"
                >
                  <h4>My Open Auctions</h4> 
                </button>
              </h2>
              <div
                id="flush-collapseOne"
                className="accordion-collapse collapse"
                aria-labelledby="flush-headingTwo"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="container">
                  <div className="row">
                    <div className="card p-3">
                      <div className="d-flex justify-content-between">
                        <h6>Polycrystalline Panel | 3kW | Residential Use</h6>
                        <button
                          className="btn btn-primary"
                          data-bs-toggle="modal"
                          data-bs-target="#auctionModalP1"
                        >
                          View
                        </button>
                      </div>
                    </div>

                    <div className="card p-3 ">
                      <div className="d-flex justify-content-between">
                        <h6>Monocrystalline Panel | 70kW | Industrial Use</h6>
                        <button
                          className="btn btn-primary"
                          data-bs-toggle="modal"
                          data-bs-target="#auctionModalP2"
                        >
                          View
                        </button>
                      </div>
                    </div>

                    <div className="card p-3 ">
                      <div className="d-flex justify-content-between">
                        <h6>Mono PERC Panel | 4kW | Residential Use</h6>
                        <button
                          className="btn btn-primary"
                          data-bs-toggle="modal"
                          data-bs-target="#auctionModalP3"
                        >
                          View
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="accordion-item">
              <h2 className="accordion-header" id="flush-headingTwo">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseTwo"
                  aria-expanded="false"
                  aria-controls="flush-collapseTwo"
                >
                  <h4>My Quotes</h4> 
                </button>
              </h2>
              <div
                id="flush-collapseTwo"
                className="accordion-collapse collapse"
                aria-labelledby="flush-headingTwo"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="container">
                  <div className="row">
                    <div className="card p-3 ">
                      <div className="d-flex justify-content-between">
                        <h6>Monocrystalline panel | 50kW | Commercial Use</h6>
                        <button
                          className="btn btn-primary"
                          data-bs-toggle="modal"
                          data-bs-target="#quotesModalP1"
                        >
                          View
                        </button>
                      </div>
                    </div>

                    <div className="card p-3">
                      <div className="d-flex justify-content-between">
                        <h6>Polycrystalline Panel | 2kW | Residential Use</h6>
                        <button
                          className="btn btn-primary"
                          data-bs-toggle="modal"
                          data-bs-target="#quotesModalP2"
                        >
                          View
                        </button>
                      </div>
                    </div>

                    <div className="card p-3">
                      <div className="d-flex justify-content-between">
                        <h6>Mono PERC Panel | 4kW | Residential Use</h6>
                        <button
                          className="btn btn-primary"
                          data-bs-toggle="modal"
                          data-bs-target="#quotesModalP3"
                        >
                          View
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  <h4>My Order</h4> 
                </button>
              </h2>
              <div
                id="collapseThree"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <div class="accordion" id="accordionExample">
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingOne">
                        <button
                          class="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          Monocrystalline panel | 50kW | Commercial Use
                        </button>
                      </h2>
                      <div
                        id="collapseOne"
                        class="accordion-collapse collapse show"
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body">
                          <div className="container-fluid py-5">
                            <div className="py-5 timeline">
                              <ul class="base-timeline">
                                <li class="base-timeline__item">
                                  <span class="base-timeline__summary-text">
                                    order placed
                                  </span>
                                </li>
                                <li class="base-timeline__item">
                                  <span class="base-timeline__summary-text">
                                    Request sent to manufacturer
                                  </span>
                                </li>
                                <li class="base-timeline__item">
                                  <span class="base-timeline__summary-text">
                                    In-transit
                                  </span>
                                </li>
                                <li class="base-timeline__item deliverd">
                                  <span class="base-timeline__summary-text">
                                    Deliverd
                                  </span>
                                </li>
                                <li class="base-timeline__item">
                                  <span class="base-timeline__summary-text">
                                    Installation in process
                                  </span>
                                </li>
                                <li class="base-timeline__item">
                                  <span class="base-timeline__summary-text">
                                    Installation Done
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingTwo">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                        >
                          Polycrystalline Panel | 2kW | Residential Use
                        </button>
                      </h2>
                      <div
                        id="collapseTwo"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingTwo"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body">
                          <div className="container-fluid py-5">
                            <div className="py-5 timeline">
                              <ul class="base-timeline">
                                <li class="base-timeline__item">
                                  <span class="base-timeline__summary-text">
                                    order placed
                                  </span>
                                </li>
                                <li class="base-timeline__item">
                                  <span class="base-timeline__summary-text">
                                    Request sent to manufacturer
                                  </span>
                                </li>
                                <li class="base-timeline__item">
                                  <span class="base-timeline__summary-text">
                                    In-transit
                                  </span>
                                </li>
                                <li class="base-timeline__item">
                                  <span class="base-timeline__summary-text">
                                    Deliverd
                                  </span>
                                </li>
                                <li class="base-timeline__item installation">
                                  <span class="base-timeline__summary-text">
                                    Installation in process
                                  </span>
                                </li>
                                <li class="base-timeline__item">
                                  <span class="base-timeline__summary-text">
                                    Installation Done
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingfour">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapsefour"
                          aria-expanded="false"
                          aria-controls="collapsefour"
                        >
                          Mono PERC Panel | 4kW | Residential Use
                        </button>
                      </h2>
                      <div
                        id="collapsefour"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingfour"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body">
                          <div className="container-fluid py-5">
                            <div className="py-5 timeline">
                              <ul class="base-timeline">
                                <li class="base-timeline__item order">
                                  <span class="base-timeline__summary-text">
                                    order placed
                                  </span>
                                </li>
                                <li class="base-timeline__item">
                                  <span class="base-timeline__summary-text">
                                    Request sent to manufacturer
                                  </span>
                                </li>
                                <li class="base-timeline__item">
                                  <span class="base-timeline__summary-text">
                                    In-transit
                                  </span>
                                </li>
                                <li class="base-timeline__item">
                                  <span class="base-timeline__summary-text">
                                    Deliverd
                                  </span>
                                </li>
                                <li class="base-timeline__item">
                                  <span class="base-timeline__summary-text">
                                    Installation in process
                                  </span>
                                </li>
                                <li class="base-timeline__item">
                                  <span class="base-timeline__summary-text">
                                    Installation Done
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*Auction Product 1 */}
      <div
        class="modal fade"
        id="auctionModalP1"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content p-3">
            <div class="modal-header">
              <h5 className="modal-title">{auctionTitle}</h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={onHandleClose}
              ></button>
            </div>
            <div class="modal-body">
              {currentStep === 0 && (
                <div>
                  <div className="flex-row">
                    <img
                      src={PromoCode}
                      className="discount"
                      alt="Promo Code"
                      height={32}
                      width={32}
                    />
                    <span className="ms-3">
                      {" "}
                      you can avail about 40% subsidy on this order.{" "}
                      <a
                        className="ms-5"
                        href="https://solarrooftop.gov.in/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Click Here
                      </a>{" "}
                      to know more
                    </span>
                  </div>
                  <div className="row row-cols-1 row-cols-3 g-4 mt-2">
                    <div className="col-4">
                      <img
                        src={ECEIndia}
                        className="imng-fluid mb-3"
                        height={42}
                        width={108}
                        alt="ECEIndia"
                      />
                    </div>
                    <div className="col-4">
                      <p> 1,03,000/-</p>
                    </div>
                    <div className="col-4">
                      <button
                        className=" btn btn-primary"
                        type="button"
                        onClick={handleConfirm}
                      >
                        Submit
                      </button>
                    </div>
                  </div>

                  <div className="row row-cols-1 row-cols-3 g-4 mt-2">
                    <div className="col">
                      <img
                        src={WaareeSolor}
                        className="imng-fluid mb-3"
                        height={42}
                        width={108}
                        alt="WaareeSolor"
                      />
                    </div>
                    <div className="col">
                      <p> 1,04,000/-</p>
                    </div>

                    <div className="col">
                      <button
                        className=" btn btn-primary"
                        type="button"
                        onClick={handleConfirm}
                      >
                        Submit
                      </button>
                    </div>
                  </div>

                  <div className="row row-cols-1 row-cols-3 g-4 mt-2">
                    <div className="col-4">
                      <img
                        src={AnanyaSolar}
                        className="imng-fluid mb-3"
                        height={42}
                        width={108}
                        alt="AnanyaSolar"
                      />
                    </div>
                    <div className="col-4">
                      <p> 1,10,500/-</p>
                    </div>
                    <div className="col-4">
                      <button
                        className="btn btn-primary "
                        type="button"
                        onClick={handleConfirm}
                      >
                        Submit
                      </button>
                    </div>
                  </div>

                  <div className="row row-cols-1 row-cols-3 g-4 mt-2">
                    <div className="col-4">
                      <img
                        src={AdityaSolar}
                        className="imng-fluid mb-4"
                        height={38}
                        width={108}
                        alt="AdityaSolar"
                      />
                    </div>
                    <div className="col-4">
                      <p> 1,15,000/-</p>
                    </div>

                    <div className="col-4">
                      <button
                        className=" btn btn-primary"
                        type="button"
                        onClick={handleConfirm}
                      >
                        Submit
                      </button>
                    </div>
                  </div>

                  <div className="row row-cols-1 row-cols-md-3 g-4 mt-2">
                    <div className="col-4">
                      <img
                        src={AdaniSolar}
                        className="imng-fluid mb-3"
                        height={45}
                        width={108}
                        alt="AdaniSolar"
                      />
                    </div>
                    <div className="col-4">
                      <p>1,16,500/-</p>
                    </div>
                    <div className="col-4">
                      <button
                        className=" btn btn-primary "
                        type="button"
                        onClick={handleConfirm}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              )}

              {currentStep === 1 && (
                <div>
                  <span
                    style={{
                      fontSize: "50px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    role="img"
                    aria-label="image"
                  >
                    🎉
                  </span>
                  <h1 className="text-center">Congratulations!</h1>
                  <p className="text-center">Your order placed successfully.</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Auction Product 2 */}
      <div
        class="modal fade"
        id="auctionModalP2"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content p-3">
            <div class="modal-header">
              <h5 className="modal-title">{auctionTitle}</h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={onHandleClose}
              ></button>
            </div>
            <div class="modal-body">
              {currentStep === 0 && (
                <div>
                  <div className="row row-cols-1 row-cols-3 g-4 mt-2">
                    <div className="col">
                      <img
                        src={AdityaSolar}
                        className="imng-fluid mb-3"
                        height={42}
                        width={108}
                        alt="AdityaSolar"
                      />
                    </div>
                    <div className="col">
                      <p> 22,30,500/-</p>
                    </div>

                    <div className="col">
                      <button
                        className=" btn btn-primary"
                        type="button"
                        onClick={handleConfirm}
                      >
                        Submit
                      </button>
                    </div>
                  </div>

                  <div className="row row-cols-1 row-cols-3 g-4 mt-2">
                    <div className="col-4">
                      <img
                        src={ECEIndia}
                        className="imng-fluid mb-3"
                        height={42}
                        width={108}
                        alt="ECEIndia"
                      />
                    </div>
                    <div className="col-4">
                      <p> 22,32,500/-</p>
                    </div>
                    <div className="col-4">
                      <button
                        className="btn btn-primary "
                        type="button"
                        onClick={handleConfirm}
                      >
                        Submit
                      </button>
                    </div>
                  </div>

                  <div className="row row-cols-1 row-cols-3 g-4 mt-2">
                    <div className="col-4">
                      <img
                        src={AnanyaSolar}
                        className="imng-fluid mb-4"
                        height={38}
                        width={108}
                        alt="AnanyaSolar"
                      />
                    </div>
                    <div className="col-4">
                      <p> 23,30,500/-</p>
                    </div>

                    <div className="col-4">
                      <button
                        className=" btn btn-primary"
                        type="button"
                        onClick={handleConfirm}
                      >
                        Submit
                      </button>
                    </div>
                  </div>

                  <div className="row row-cols-1 row-cols-md-3 g-4 mt-2">
                    <div className="col-4">
                      <img
                        src={MahindraSolar}
                        className="imng-fluid mb-3"
                        height={45}
                        width={108}
                        alt="MahindraSolar"
                      />
                    </div>
                    <div className="col-4">
                      <p>23,55,500/-</p>
                    </div>
                    <div className="col-4">
                      <button
                        className=" btn btn-primary "
                        type="button"
                        onClick={handleConfirm}
                      >
                        Submit
                      </button>
                    </div>
                  </div>

                  <div className="row row-cols-1 row-cols-3 g-4 mt-2">
                    <div className="col-4">
                      <img
                        src={VikramSolar}
                        className="imng-fluid mb-3"
                        height={42}
                        width={108}
                        alt="VikramSolar"
                      />
                    </div>
                    <div className="col-4">
                      <p> 24,00,000/-</p>
                    </div>
                    <div className="col-4">
                      <button
                        className=" btn btn-primary"
                        type="button"
                        onClick={handleConfirm}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              )}

              {currentStep === 1 && (
                <div>
                  <span
                    style={{
                      fontSize: "50px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    role="img"
                    aria-label="image"
                  >
                    🎉
                  </span>
                  <h1 className="text-center">Congratulations!</h1>
                  <p className="text-center">Your order placed successfully.</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Auction Product 3 */}
      <div
        class="modal fade"
        id="auctionModalP3"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content p-3">
            <div class="modal-header">
              <h5 className="modal-title">{auctionTitle}</h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={onHandleClose}
              ></button>
            </div>
            <div className="modal-body">
              {currentStep === 0 && (
                <div>
                  <div className="flex-row">
                    <img
                      src={PromoCode}
                      className="discount"
                      alt="Promo Code"
                      height={32}
                      width={32}
                    />
                    <span className="ms-3">
                      {" "}
                      you can avail about 20% subsidy on this order.{" "}
                      <a
                        className="ms-5"
                        href="https://solarrooftop.gov.in/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Click Here
                      </a>{" "}
                      to know more
                    </span>
                  </div>
                  <div className="row row-cols-1 row-cols-3 g-4 mt-2">
                    <div className="col-4">
                      <img
                        src={ECEIndia}
                        className="imng-fluid mb-3"
                        height={42}
                        width={108}
                        alt="ECEIndia"
                      />
                    </div>
                    <div className="col-4">
                      <p> 1,88,500/-</p>
                    </div>
                    <div className="col-4">
                      <button
                        className=" btn btn-primary"
                        type="button"
                        onClick={handleConfirm}
                      >
                        Submit
                      </button>
                    </div>
                  </div>

                  <div className="row row-cols-1 row-cols-3 g-4 mt-2">
                    <div className="col">
                      <img
                        src={AdityaSolar}
                        className="imng-fluid mb-3"
                        height={42}
                        width={108}
                        alt="AdityaSolar"
                      />
                    </div>
                    <div className="col">
                      <p> 1,90,000/-</p>
                    </div>

                    <div className="col">
                      <button
                        className=" btn btn-primary"
                        type="button"
                        onClick={handleConfirm}
                      >
                        Submit
                      </button>
                    </div>
                  </div>

                  <div className="row row-cols-1 row-cols-3 g-4 mt-2">
                    <div className="col-4">
                      <img
                        src={AnanyaSolar}
                        className="imng-fluid mb-3"
                        height={42}
                        width={108}
                        alt="AnanyaSolar"
                      />
                    </div>
                    <div className="col-4">
                      <p> 2,37,000/-</p>
                    </div>
                    <div className="col-4">
                      <button
                        className="btn btn-primary "
                        type="button"
                        onClick={handleConfirm}
                      >
                        Submit
                      </button>
                    </div>
                  </div>

                  <div className="row row-cols-1 row-cols-3 g-4 mt-2">
                    <div className="col-4">
                      <img
                        src={LoomSolar}
                        className="imng-fluid mb-4"
                        height={38}
                        width={108}
                        alt="LoomSolar"
                      />
                    </div>
                    <div className="col-4">
                      <p> 2,38,900/-</p>
                    </div>

                    <div className="col-4">
                      <button
                        className=" btn btn-primary"
                        type="button"
                        onClick={handleConfirm}
                      >
                        Submit
                      </button>
                    </div>
                  </div>

                  <div className="row row-cols-1 row-cols-md-3 g-4 mt-2">
                    <div className="col-4">
                      <img
                        src={LuminousSolar}
                        className="imng-fluid mb-3"
                        height={45}
                        width={108}
                        alt="LuminousSolar"
                      />
                    </div>
                    <div className="col-4">
                      <p>2,53,000/-</p>
                    </div>
                    <div className="col-4">
                      <button
                        className=" btn btn-primary "
                        type="button"
                        onClick={handleConfirm}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              )}

              {currentStep === 1 && (
                <div>
                  <span
                    style={{
                      fontSize: "50px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    role="img"
                    aria-label="image"
                  >
                    🎉
                  </span>
                  <h1 className="text-center">Congratulations!</h1>
                  <p className="text-center">Your order placed successfully.</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Quotes Product 1 */}
      <div
        class="modal fade"
        id="quotesModalP1"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content p-3">
            <div class="modal-header">
              <h5 className="modal-title">{quotesTitle}</h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={onHandleClose}
              ></button>
            </div>
            <div class="modal-body">
              {currentStep === 0 && (
                <div>
                  <div className="row row-cols-1 row-cols-3 g-4 mt-2">
                    <div className="col">
                      <img
                        src={AnanyaSolar}
                        className="imng-fluid mb-3"
                        height={42}
                        width={108}
                        alt="AnanyaSolar"
                      />
                    </div>
                    <div className="col">
                      <p> 17,29,105/-</p>
                    </div>

                    <div className="col">
                      <button
                        className=" btn btn-primary"
                        type="button"
                        onClick={handleConfirm1}
                      >
                        Submit
                      </button>
                    </div>
                  </div>

                  <div className="row row-cols-1 row-cols-3 g-4 mt-2">
                    <div className="col-4">
                      <img
                        src={AdityaSolar}
                        className="imng-fluid mb-3"
                        height={42}
                        width={108}
                        alt="AdityaSolar"
                      />
                    </div>
                    <div className="col-4">
                      <p> 17,34,330/-</p>
                    </div>
                    <div className="col-4">
                      <button
                        className="btn btn-primary "
                        type="button"
                        onClick={handleConfirm1}
                      >
                        Submit
                      </button>
                    </div>
                  </div>

                  <div className="row row-cols-1 row-cols-3 g-4 mt-2">
                    <div className="col-4">
                      <img
                        src={MahindraSolar}
                        className="imng-fluid mb-4"
                        height={38}
                        width={108}
                        alt="MahindraSolar"
                      />
                    </div>
                    <div className="col-4">
                      <p> 17,51,715/-</p>
                    </div>

                    <div className="col-4">
                      <button
                        className=" btn btn-primary"
                        type="button"
                        onClick={handleConfirm1}
                      >
                        Submit
                      </button>
                    </div>
                  </div>

                  <div className="row row-cols-1 row-cols-md-3 g-4 mt-2">
                    <div className="col-4">
                      <img
                        src={ECEIndia}
                        className="imng-fluid mb-3"
                        height={45}
                        width={108}
                        alt="ECEIndia"
                      />
                    </div>
                    <div className="col-4">
                      <p>17,60,157/-</p>
                    </div>
                    <div className="col-4">
                      <button
                        className=" btn btn-primary "
                        type="button"
                        onClick={handleConfirm1}
                      >
                        Submit
                      </button>
                    </div>
                  </div>

                  <div className="row row-cols-1 row-cols-3 g-4 mt-2">
                    <div className="col-4">
                      <img
                        src={WaareeSolor}
                        className="imng-fluid mb-3"
                        height={42}
                        width={108}
                        alt="WaareeSolor"
                      />
                    </div>
                    <div className="col-4">
                      <p> 19,39,150/-</p>
                    </div>
                    <div className="col-4">
                      <button
                        className=" btn btn-primary"
                        type="button"
                        onClick={handleConfirm1}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              )}

              {currentStep === 1 && (
                <div>
                  <span
                    style={{
                      fontSize: "50px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    role="img"
                    aria-label="image"
                  >
                    🎉
                  </span>
                  <h1 className="text-center">Congratulations!</h1>
                  <p className="text-center">Your order placed successfully.</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Quotes Product 2 */}
      <div
        class="modal fade"
        id="quotesModalP2"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content p-3">
            <div class="modal-header">
              <h5 className="modal-title">{quotesTitle}</h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={onHandleClose}
              ></button>
            </div>
            <div class="modal-body">
              {currentStep === 0 && (
                <div>
                  <div className="flex-row">
                    <img
                      src={PromoCode}
                      className="discount"
                      alt="Promo Code"
                      height={32}
                      width={32}
                    />
                    <span className="ms-3">
                      {" "}
                      you can avail about 40% subsidy on this order.{" "}
                      <a
                        className="ms-5"
                        href="https://solarrooftop.gov.in/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Click Here
                      </a>{" "}
                      to know more
                    </span>
                  </div>
                  <div className="row row-cols-1 row-cols-3 g-4 mt-2">
                    <div className="col-4">
                      <img
                        src={AnanyaSolar}
                        className="imng-fluid mb-3"
                        height={42}
                        width={108}
                        alt="AnanyaSolar"
                      />
                    </div>
                    <div className="col-4">
                      <p> 79,100/-</p>
                    </div>
                    <div className="col-4">
                      <button
                        className=" btn btn-primary"
                        type="button"
                        onClick={handleConfirm1}
                      >
                        Submit
                      </button>
                    </div>
                  </div>

                  <div className="row row-cols-1 row-cols-3 g-4 mt-2">
                    <div className="col">
                      <img
                        src={ECEIndia}
                        className="imng-fluid mb-3"
                        height={42}
                        width={108}
                        alt="ECEIndia"
                      />
                    </div>
                    <div className="col">
                      <p> 81,130/-</p>
                    </div>

                    <div className="col">
                      <button
                        className=" btn btn-primary"
                        type="button"
                        onClick={handleConfirm1}
                      >
                        Submit
                      </button>
                    </div>
                  </div>

                  <div className="row row-cols-1 row-cols-3 g-4 mt-2">
                    <div className="col-4">
                      <img
                        src={AdityaSolar}
                        className="imng-fluid mb-3"
                        height={42}
                        width={108}
                        alt="AdityaSolar"
                      />
                    </div>
                    <div className="col-4">
                      <p> 84,095/-</p>
                    </div>
                    <div className="col-4">
                      <button
                        className="btn btn-primary "
                        type="button"
                        onClick={handleConfirm1}
                      >
                        Submit
                      </button>
                    </div>
                  </div>

                  <div className="row row-cols-1 row-cols-3 g-4 mt-2">
                    <div className="col-4">
                      <img
                        src={TataPowerSolor}
                        className="imng-fluid mb-4"
                        height={38}
                        width={108}
                        alt="TataPowerSolor"
                      />
                    </div>
                    <div className="col-4">
                      <p> 89,020/-</p>
                    </div>

                    <div className="col-4">
                      <button
                        className=" btn btn-primary"
                        type="button"
                        onClick={handleConfirm1}
                      >
                        Submit
                      </button>
                    </div>
                  </div>

                  <div className="row row-cols-1 row-cols-md-3 g-4 mt-2">
                    <div className="col-4">
                      <img
                        src={AdaniSolar}
                        className="imng-fluid mb-3"
                        height={45}
                        width={108}
                        alt="AdaniSolar"
                      />
                    </div>
                    <div className="col-4">
                      <p>92,110/-</p>
                    </div>
                    <div className="col-4">
                      <button
                        className=" btn btn-primary "
                        type="button"
                        onClick={handleConfirm1}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              )}

              {currentStep === 1 && (
                <div>
                  <span
                    style={{
                      fontSize: "50px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    role="img"
                    aria-label="image"
                  >
                    🎉
                  </span>
                  <h1 className="text-center">Congratulations!</h1>
                  <p className="text-center">Your order placed successfully.</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Quotes Product 3 */}
      <div
        class="modal fade"
        id="quotesModalP3"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content p-3">
            <div class="modal-header">
              <h5 className="modal-title">{quotesTitle}</h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={onHandleClose}
              ></button>
            </div>
            <div class="modal-body">
              {currentStep === 0 && (
                <div>
                  <div className="flex-row">
                    <img
                      src={PromoCode}
                      className="discount"
                      alt="Promo Code"
                      height={32}
                      width={32}
                    />
                    <span className="ms-3">
                      {" "}
                      you can avail about 20% subsidy on this order.{" "}
                      <a
                        className="ms-5"
                        href="https://solarrooftop.gov.in/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Click Here
                      </a>{" "}
                      to know more
                    </span>
                  </div>
                  <div className="row row-cols-1 row-cols-3 g-4 mt-2">
                    <div className="col-4">
                      <img
                        src={AnanyaSolar}
                        className="imng-fluid mb-3"
                        height={42}
                        width={108}
                        alt="AnanyaSolar"
                      />
                    </div>
                    <div className="col-4">
                      <p> 1,90,415/-</p>
                    </div>
                    <div className="col-4">
                      <button
                        className=" btn btn-primary"
                        type="button"
                        onClick={handleConfirm1}
                      >
                        Submit
                      </button>
                    </div>
                  </div>

                  <div className="row row-cols-1 row-cols-3 g-4 mt-2">
                    <div className="col">
                      <img
                        src={ECEIndia}
                        className="imng-fluid mb-3"
                        height={42}
                        width={108}
                        alt="ECEIndia"
                      />
                    </div>
                    <div className="col">
                      <p> 2,22,570/-</p>
                    </div>

                    <div className="col">
                      <button
                        className=" btn btn-primary"
                        type="button"
                        onClick={handleConfirm1}
                      >
                        Submit
                      </button>
                    </div>
                  </div>

                  <div className="row row-cols-1 row-cols-3 g-4 mt-2">
                    <div className="col-4">
                      <img
                        src={LoomSolar}
                        className="imng-fluid mb-3"
                        height={42}
                        width={108}
                        alt="LoomSolar"
                      />
                    </div>
                    <div className="col-4">
                      <p> 2,38,940/-</p>
                    </div>
                    <div className="col-4">
                      <button
                        className="btn btn-primary "
                        type="button"
                        onClick={handleConfirm1}
                      >
                        Submit
                      </button>
                    </div>
                  </div>

                  <div className="row row-cols-1 row-cols-3 g-4 mt-2">
                    <div className="col-4">
                      <img
                        src={MahindraSolar}
                        className="imng-fluid mb-4"
                        height={38}
                        width={108}
                        alt="MahindraSolar"
                      />
                    </div>
                    <div className="col-4">
                      <p> 2,53,260/-</p>
                    </div>

                    <div className="col-4">
                      <button
                        className=" btn btn-primary"
                        type="button"
                        onClick={handleConfirm1}
                      >
                        Submit
                      </button>
                    </div>
                  </div>

                  <div className="row row-cols-1 row-cols-md-3 g-4 mt-2">
                    <div className="col-4">
                      <img
                        src={TataPowerSolor}
                        className="imng-fluid mb-3"
                        height={45}
                        width={108}
                        alt="TataPowerSolor"
                      />
                    </div>
                    <div className="col-4">
                      <p>2,90,000/-</p>
                    </div>
                    <div className="col-4">
                      <button
                        className=" btn btn-primary "
                        type="button"
                        onClick={handleConfirm1}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              )}

              {currentStep === 1 && (
                <div>
                  <span
                    style={{
                      fontSize: "50px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    role="img"
                    aria-label="image"
                  >
                    🎉
                  </span>
                  <h1 className="text-center">Congratulations!</h1>
                  <p className="text-center">Your order placed successfully.</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
