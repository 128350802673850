import React from "react";
import "./Work.css";
import img from "../../img/we-work.png";
import BPTLogo from '../../img/Logo/logo1.png'

export default function Work() {
  return (
    <div className="container py-3">
      <h1 className="text-center" style={{ fontWeight: "bold" }}>
        <span>How</span> <img src={BPTLogo} alt="BPT logo" height={110} style={{margin: "0px -35px -10px -20px"}}/> Works
      </h1>
      <hr />
      <div className="row">
        <img src={img} className="imng-fluid" height={619} style={{marginTop: "-65px"}} alt="How it works"/>
      </div>

      <h1 className="text-center mb-5"><b>Why Us</b> </h1>
      <hr />
      <div className="row row-cols-1 row-cols-md-5 g-4 mt-3 mb-5">
        <div className="col" style={{backgroundColor: "#0D5142"}}>
          <p className="why-text">Procure time-efficiently</p>
        </div>
        <div className="col" style={{backgroundColor: "#1B6B44"}}>
          <p className="why-text">Campare multiple suppliers</p>
        </div>
        <div className="col" style={{backgroundColor: "#96A53F"}}>
          <p className="why-text">Scatter your allotment by choice</p>
        </div>
        <div className="col" style={{backgroundColor: "#1B6B44"}}>
          <p className="why-text">Get your large volume needs met</p>
        </div>
        <div className="col" style={{backgroundColor: "#0D5142"}}>
          <p className="why-text">Hassle Free tracking</p>
        </div>
      </div>
    </div>
  );
}
