import React from "react";
import "./Footer.css";
import img from "../../img/Footer/footer-logo.png";

export default function Footer() {
  return (
    <div className="footer-section py-5">
      <div className="container ">
        <div className="row row-cols-1 row-cols-3">
          <div className="col">
            <img
              src={img}
              className="imng-fluid"
              height={220}
              style={{marginTop: "-35px"}}
              alt="Logo"
            />
          </div>

          <div className="col">
            <h3 className="mb-3">All Link</h3>

            <div className="mb-3">
              <a href="/" style={{ textDecoration: "none", color: "white" }}>
                Home
              </a>
            </div>

            <div className="mb-3">
              <a
                href="/about"
                style={{ textDecoration: "none", color: "white" }}
              >
                About
              </a>
            </div>

            <div className="mb-3">
               Biomass Briquettees (Coming Soon)
            
            </div>
          </div>
          <div className="col">
            <h3 className="mb-3">Contact Us</h3>
            <p>5th floor, Ideas to impacts Hub, Baner, Pune-411045.</p>

            <p>
              Reach out to us incase of any query{" "}
              <a
                href="mailto:info@bidprotrade.com"
                style={{ textDecoration: "none", color: "white" }}
              >
                info@bidprotrade.com
              </a>
            </p>
            <div className="footer-icon mt-2">
              <a
                href="https://www.linkedin.com/company/bidprotrade/"
                target="_blank"
                rel="noreferrer"
              >
                <i className="bi bi-linkedin pe-3"></i>
              </a>
              <a
                href="https://www.facebook.com/bidprotrade"
                target="_blank"
                rel="noreferrer"
              >
                <i className="bi bi-facebook pe-3"></i>
              </a>
              <a
                href="https://www.instagram.com/bidprotrade_solutions/"
                target="_blank"
                rel="noreferrer"
              >
                <i className="bi bi-instagram pe-3"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
